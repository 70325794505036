.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(200, 200, 200);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.faIcon {
  font-size: 4rem;
  color: rgba(0, 0, 0, 0.3);
  transition: color 0.2s;
}

.faIconDark {
  font-size: 4rem;
  color: rgba(255, 255, 255, 0.3);
  transition: color 0.2s;
}

.helperIcon {
  font-size: 1.5rem;
}

.faIcon:hover {
  cursor: pointer;
  color: rgba(115, 103, 255, 0.6);
}

.faIconDark:hover {
  cursor: pointer;
  color: rgba(126, 119, 202, 1);
}

.cardImage {
  max-width: 100%;
  height: 50%;
  min-height: 200px;
}

.cardDescription {
  position: "absolute";
  font-size: 0.95rem;
}

.toolbarIcon {
  height: 64px;
  vertical-align: middle;
}

.toolbarIcon:hover {
  cursor: pointer;
}
